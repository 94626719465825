import { defineStore } from "pinia";
import http from "../http";


export const useItemsStore = defineStore("itemsStore", {
  state: () => ({
    items: [],
    warehouseItems: [],
    refillItems: [],
    isLoading:false,
  }),
  getters: {
    getItems() {
      return this.items
    },
    getWarehouseItems() {
      return this.warehouseItems
    },
    getRefillItems() {
      return this.refillItems
    },
    getAllWarehouseItems(){
      return this.allWarehouseItems
    },
    getIsLoading(){
      return this.isLoading
    }
  },
  actions: {
    async setItems() {
      try {
        const response = await http.get("items");
        if (response && response.data && response.data.data) {
          this.items = response.data.data;
        } 
      } catch (error) {
      }
    },
    changeItems(payload) {
      this.items = payload
    },
    createItem(item) {
      http.post("items", item).then((response) => {
        let data = response.data
        this.items.push(data)
      });
    },
    editItem(itemId, payload) {
      http.put(`items/${itemId}`, payload).then((response) => {
        const data = response.data.data
        var newArr = this.items.map(obj => {
          if (data.id === obj.id) {
            return data; // Replace object if IDs match
          }
          return obj; // Keep the original object if IDs don't match
        });
        this.items = newArr
      })
    },
   


    deleteItem(itemId) {
      http.delete(`items/${itemId}`).then((response) => {
        let data = response
        if (data.status === 200) {
          this.items = this.items.filter(item => item.id !== itemId);
        }
      });
    },
    importExcel(event) {
      const file = event[0];

      // Create a FormData object
      const formData = new FormData();
      formData.append('excel_file', file);

      // Send the file using Axios
      http.post('/items/operations/import', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(response => {
          let data = response.data
          // data.data.forEach(element => {
          //   this.items.push(element)
          // });
        })
        .catch(error => {
          // Handle any errors that occur during the request
        });
    },

    exportExcel() {
      http.get("items/operations/export", { responseType: 'blob' })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'items.xlsx');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
        });
    },

    itemsByWarehouse(warehouseId) {
      http.get(`warehouse-items/items-by-warehouse/${warehouseId}`).then((response) => {
        this.warehouseItems = response.data.data
      })
    },
    
    setWarehouseEmpty(){
      this.warehouseItems = []
    },
    setRefillItems(event) {
      this.isLoading = true; // Start loading indicator
      this.refillItems = []
    
      http.get(`operations/inquirey?warehouse_id=${event}`)
        .then((response) => {
          this.refillItems = response.data.data;
    
          // Add 'refillQty' property initialized to 0 for each item
          const updatedArray = this.refillItems.map(obj => ({
            ...obj,
            refillQty: 0
          }));
          this.refillItems = updatedArray;
        })
        .catch((error) => {
          // Optionally, display an error message to the user
          this.$toast.error(this.$t('errors.fetchRefillItemsFailed'));
        })
        .finally(() => {
          this.isLoading = false; // Stop loading indicator
        });
    },
    emptyRefillItems() {
      this.refillItems = []
    },

    createRefillOperation(payload) {
      http.post("operations", payload).then((response) => {
        // let data = response.data.data
        // this.items.push(data)
      });
    },
    
    


  },
});
