import { defineStore } from "pinia";
import http from "../http";
export const useWarehouseItemsStore = defineStore("warehouseItemsStore", {
    state: () => ({
        warehouseItems: []
    }),
    getters: {
        getWarehouseItems() {
            return this.warehouseItems
        }
    },
    actions: {
        setWarehouseItems() {
            http.get('warehouse-items').then((response) => {
              
                this.warehouseItems = response.data.data
              
            })
        },
        createWarehouseItem(payload){
            http.post("warehouse-items", payload).then((response) => {
                let data = response.data.data[0]
                this.warehouseItems.push(data)
              });
        },
        editWarehouseItem(itemId, payload) {
            http.put(`warehouse-items/${itemId}`, payload).then((response) => {
              const data = response.data.data
              var newArr = this.warehouseItems.map(obj => {
                if (data.id === obj.id) {
                  return data; // Replace object if IDs match
                }
                return obj; // Keep the original object if IDs don't match
              });
              this.warehouseItems = newArr
            })
          },
        deleteWarehouseItem(itemId) {
            http.delete(`warehouse-items/${itemId}`).then((response) => {
                let data = response
                if (data.status === 200) {
                    this.warehouseItems = this.warehouseItems.filter(item => item.id !== itemId);
                }
            });
        },
        importExcel(event, th) {
            const file = event[0];
      
            // Create a FormData object
            const formData = new FormData();
            formData.append('excel_file', file);
      
            // Send the file using Axios
            http.post('/warehouse-items/operations/import', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
              .then(response => {
                let data = response.data
                // data.data.forEach(element => {
                //   this.warehouseItems.push(element)
                // });
              })
              .catch(error => {
                // Handle any errors that occur during the request
                // console.error(error);
              });
          },
    },
});